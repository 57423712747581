import { styled } from '@mui/material';
import * as React from 'react';
import { useAppSelector as useSelector } from 'Store/index';

import { alerts } from '../../../config/generalSettings';
import Censorable from '../../Censorable';
import { Container, Item } from '../../Grid';
import { AlertsPopUps, AlertsPopover } from './AlertsNotificationIcon';

const NameDisplay = styled(Item)({
  maxWidth: '40rem',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  '@media only screen and (max-width: 1400px)': {
    maxWidth: '15rem',
  },
});

const PatientDetails = ({ patientData }: { patientData: IPatientStore['data'] }): JSX.Element => {
  const isLoading = useSelector((s: { documents: IDocumentStore }) => s.documents.isLoading);

  const nameString = patientData ? patientData.lastNames.toUpperCase() + ', ' + patientData.firstNames : null;

  return (
    <Item style={{ marginRight: '2rem' }}>
      <Container justifyContent="center">
        <Item
          style={{
            textAlign: 'center',
            color: isLoading === true && !patientData ? 'transparent' : undefined,
          }}
        >
          <h2>
            <Container>
              {nameString && (
                <NameDisplay>
                  <Censorable>{nameString}</Censorable>
                </NameDisplay>
              )}
              {patientData?.ssn && (
                <Item style={{ marginLeft: '1rem', fontSize: '1.6rem', fontWeight: 400 }}>
                  <Censorable>{patientData.ssn}</Censorable>
                </Item>
              )}
            </Container>
          </h2>
        </Item>
      </Container>
    </Item>
  );
};

export const PatientInfoAlerts = ({
  patientData,
  session,
  documents,
  myAppDocs,
}: {
  patientData: IPatientStore['data'];
  session?: ISessionStore;
  documents: IDocumentStore['sortedAndMergedDocuments'];
  myAppDocs: IMyAppStore['sortedAndMergedDocuments'];
}): JSX.Element => {
  const enabledAlerts =
    session?.platforms?.selected && Array.isArray(documents) && documents.length > 0
      ? alerts[session.platforms?.selected]?.filter((a) => a.rule(documents, myAppDocs)) || []
      : [];

  return (
    <Container style={{ height: '100%' }}>
      <AlertsPopover alerts={enabledAlerts} />
      <AlertsPopUps alerts={enabledAlerts} />
      <PatientDetails patientData={patientData} />
    </Container>
  );
};
