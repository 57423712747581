import { css } from 'styled-components';
import { isIE } from '../../utility/isIE';
import colors from './colors';

const globalStyle = css`
  body {
    color: ${colors.primaryText};
    background-color: ${colors.defaultBackground};
    margin: 0px;
    ${isIE() ? 'overflow-x: hidden;' : ''}
    font-size: 1.6rem;
    /*line-height: 2rem;*/
  }
  html {
    font-family: 'Titillium Web';
    font-size: 10px;
  }
  a {
    text-decoration: none;
    cursor: pointer;
    color: ${colors.primary};
    &:hover {
      color: ${colors.secondary};
    }
  }
  input {
    ::placeholder {
      color: ${colors.inputPlaceholder} !important;
      opacity: 1 !important;
    }
  }
  h1 {
    font-size: 4.4rem;
    font-weight: 400;
    line-height: 4.8rem;
  }
  h2 {
    font-size: 2.2rem;
    font-weight: 600;
    line-height: 2.2rem;
  }
  h3 {
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 1.6rem;
  }
  .subtitle {
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 1.6rem;
  }
  .small {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.6rem;
  }
  .power-bi-container {
    height: calc(100vh - 20rem);
  }
  .power-bi-container iframe {
    border: none;
  }
`;

export default globalStyle;
