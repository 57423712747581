import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import Popper from '@mui/material/Popper';
import InputHandler from '../../../components/InputHandler';
import { Container, Item } from 'Components/Grid';
import { IPatientDataForm } from 'Store/patient/util';
import { styled } from '@mui/material';

const StyledInputRow = styled(Container)`
  padding: 0.5rem 0;
  * input {
    background-color: ${(props: { validated?: string }): string => (props.validated === 'false' ? '#ffcccc' : 'white')};
  }
`;

const PopperItem = styled('div')`
  border: 1px solid black;
  padding: 5px;
  background-color: white;
  font-size: 1.2rem;
`;

const CreationForm = ({
  form,
  onChangeNewForm,
  validated,
  patient,
  ssnValid,
  ssnInvalidMessage,
  noSearch,
}: IOwnProps): JSX.Element => (
  <React.Fragment>
    <Container justifyContent="center" style={{ fontSize: '1.6rem', marginBottom: '2rem' }}>
      <Item>
        <FormattedMessage id={noSearch || patient ? 'general.patientIdNotFound' : 'general.patientNotFound'} />
      </Item>
    </Container>
    <StyledInputRow validated={validated?.ssn === false || ssnValid === false ? 'false' : 'true'}>
      <Item xs={12} md={4} style={{ fontSize: '1.6rem' }}>
        <FormattedMessage id="general.ssn" />
      </Item>
      <Item xs={12} md={8} id="anchorel">
        <InputHandler
          type="TextField"
          editing={true}
          fullWidth={true}
          name="ssn"
          placeholder={'general.ssn'}
          formData={{
            document: { ssn: form.ssn },
            onChange: onChangeNewForm,
          }}
          autoComplete={false}
        />
      </Item>
    </StyledInputRow>
    <Popper open={!ssnValid} placement="top-start" anchorEl={document.getElementById('anchorel')}>
      <PopperItem>
        <FormattedMessage id={ssnInvalidMessage} />
      </PopperItem>
    </Popper>
    <StyledInputRow validated={validated?.firstNames === false ? 'false' : 'true'}>
      <Item xs={12} md={4} style={{ fontSize: '1.6rem' }}>
        <FormattedMessage id="general.firstNames" />
      </Item>
      <Item xs={12} md={8}>
        <InputHandler
          type="TextField"
          editing={true}
          fullWidth={true}
          name="firstNames"
          placeholder={'general.firstNames'}
          formData={{
            document: { firstNames: form.firstNames },
            onChange: onChangeNewForm,
          }}
          autoComplete={false}
        />
      </Item>
    </StyledInputRow>
    <StyledInputRow validated={validated?.lastNames === false ? 'false' : 'true'}>
      <Item xs={12} md={4} style={{ fontSize: '1.6rem' }}>
        <FormattedMessage id="general.lastNames" />
      </Item>
      <Item xs={12} md={8}>
        <InputHandler
          type="TextField"
          editing={true}
          fullWidth={true}
          name="lastNames"
          placeholder={'general.lastNames'}
          formData={{
            document: { lastNames: form.lastNames },
            onChange: onChangeNewForm,
          }}
          autoComplete={false}
        />
      </Item>
    </StyledInputRow>
    <Container style={{ fontSize: '1.6rem', margin: '2rem 0 2rem 0' }} justifyContent="center">
      <Item>
        <FormattedMessage id="general.optional" />
      </Item>
    </Container>
    <StyledInputRow>
      <Item xs={12} md={4} style={{ fontSize: '1.6rem' }}>
        <FormattedMessage id="general.streetAddress" />
      </Item>
      <Item xs={12} md={8}>
        <InputHandler
          type="TextField"
          editing={true}
          fullWidth={true}
          name="address"
          placeholder={'general.streetAddress'}
          formData={{
            document: { address: form.address },
            onChange: onChangeNewForm,
          }}
          autoComplete={false}
        />
      </Item>
    </StyledInputRow>
    <StyledInputRow>
      <Item xs={12} md={4} style={{ fontSize: '1.6rem' }}>
        <FormattedMessage id="general.streetAddressDetail" />
      </Item>
      <Item xs={12} md={8}>
        <InputHandler
          type="TextField"
          editing={true}
          fullWidth={true}
          name="addressDetail"
          placeholder={'general.streetAddressDetail'}
          formData={{
            document: { addressDetail: form.addressDetail },
            onChange: onChangeNewForm,
          }}
          autoComplete={false}
        />
      </Item>
    </StyledInputRow>
    <StyledInputRow>
      <Item xs={12} md={4} style={{ fontSize: '1.6rem' }}>
        <FormattedMessage id="general.city" />
      </Item>
      <Item xs={12} md={8}>
        <InputHandler
          type="TextField"
          editing={true}
          fullWidth={true}
          name="city"
          placeholder={'general.city'}
          formData={{
            document: { city: form.city },
            onChange: onChangeNewForm,
          }}
          autoComplete={false}
        />
      </Item>
    </StyledInputRow>
    <StyledInputRow>
      <Item xs={12} md={4} style={{ fontSize: '1.6rem' }}>
        <FormattedMessage id="general.zipCode" />
      </Item>
      <Item xs={12} md={8}>
        <InputHandler
          type="TextField"
          editing={true}
          fullWidth={true}
          name="zipCode"
          placeholder={'general.zipCode'}
          formData={{
            document: { zipCode: form.zipCode },
            onChange: onChangeNewForm,
          }}
          autoComplete={false}
        />
      </Item>
    </StyledInputRow>
    <StyledInputRow>
      <Item xs={12} md={4} style={{ fontSize: '1.6rem' }}>
        <FormattedMessage id="general.country" />
      </Item>
      <Item xs={12} md={8}>
        <InputHandler
          type="TextField"
          editing={true}
          fullWidth={true}
          name="country"
          placeholder={'general.country'}
          formData={{
            document: { country: form.country },
            onChange: onChangeNewForm,
          }}
          autoComplete={false}
        />
      </Item>
    </StyledInputRow>
  </React.Fragment>
);

interface IOwnProps {
  form: IPatientDataForm;
  onChangeNewForm: (values: TOnChangeValues) => void;
  validated: { [key: string]: boolean | string | null };
  patient?: { ssn: string; lastNames?: string; firstNames?: string };
  ssnValid?: boolean;
  ssnInvalidMessage: string;
  /** Whether or not prior search should be implied in creation form. */
  noSearch: boolean;
}

export default CreationForm;
