import Censorable from 'Components/Censorable';
import * as React from 'react';
import { useAppSelector as useSelector } from 'Store/index';
import colors from '../../config/theme/colors';
import { styleHeader } from '../../config/theme/componentTheme';

export const NamePlate = (): JSX.Element => {
  const headerHeight = parseFloat(styleHeader.height) * 10;
  const [scrollPos, setScrollPos] = React.useState<number>(window.scrollY);
  const [hidden, setHidden] = React.useState<boolean>(false);

  const handleVisibilityClick = () => {
    setHidden((prev) => !prev);
  };

  const patientData = useSelector((s: IState) => s.patient.data);

  const nameString = patientData?.lastNames
    ? patientData?.lastNames.toUpperCase() + ', ' + `${patientData.firstNames ? patientData.firstNames : ''}`
    : undefined;

  window.onscroll = () => {
    setScrollPos(window.scrollY);
  };

  if (scrollPos > headerHeight && patientData) {
    return (
      <div
        style={{
          minWidth: '2.5rem',
          height: '5rem',
          backgroundColor: colors.primary,
          position: 'fixed',
          bottom: 30,
          right: 0,
          display: 'flex',
          transform: hidden ? 'translateX(calc(100% - 2.5rem))' : 'translateX(0rem)',
          transformOrigin: 'right',
          transition: 'transform 0.3s ease-out',
        }}
      >
        <>
          <div
            style={{
              width: '2.5rem',
              height: '5rem',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={handleVisibilityClick}
          >
            <svg
              width={'100%'}
              height={'100%'}
              viewBox="0 0 16 50"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{
                transform: hidden ? 'rotate3d(0, 1, 0, 180deg)' : 'rotate3d(0, 0, 0, 0deg)',
                transition: 'transform 0.5s',
              }}
            >
              <polygon points="3,12 3,37 14,25" fill={colors.appBlue.lightest} />
            </svg>
          </div>
          <div style={{ flex: 8, paddingRight: 10 }}>
            <div
              style={{
                color: colors.white,
                fontWeight: 600,
                textAlign: 'end',
                whiteSpace: 'nowrap',
              }}
            >
              <Censorable>{nameString}</Censorable>
            </div>
            <div style={{ color: colors.white, textAlign: 'end' }}>
              <Censorable>{patientData.ssn}</Censorable>
            </div>
          </div>
        </>
      </div>
    );
  } else return <></>;
};
