import { Badge, IconButton, Popover, styled } from '@mui/material';
import {
  Search as SearchIcon,
  Send as SendIcon,
  ContactSupport,
  Info as InfoIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
  BarChart,
  Help,
  Settings as SettingsIcon,
} from '@mui/icons-material';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import PlatformCapabilities from '../../../config/capabilities';
import { PlatformToArchiveMap } from '../../../config/platformSettings';
import colors from '../../../config/theme/colors';
import { ICapabilityContextProps, withCapabilities } from '../../../containers/CapabilityHandler';
import { assertCapabilities } from '../../../store/index';
import { actions } from '../../../store/session';
import { isPlatform } from '../../../utility/typeGuards';
import { Container, Item } from '../../Grid';

import logoutIcon from '../images/icon-logout.svg';
import { fetchWithOptions } from '../../../utility/fetch';
import { parseJWTFromCookie } from '../../../utility/jwtAuthTools';
import { path } from 'ramda';
import ToolTip from 'Components/ToolTip';

const StyledTable = styled('table')`
  margin: -0.25rem 0.7rem -0.25rem -0.1rem;
  font-size: 1.8rem;
`;

const StyledMenuItem = styled('div')`
  padding: 0.5rem 1rem;
  background-color: ${colors.white};
  border-top: 1px solid ${colors.gray};
  &:hover {
    background-color: ${colors.lightGray};
  }
  cursor: pointer;
`;

const iconStyle = {
  display: 'block',
  margin: '0 0 0 0.2rem',
  padding: '0',
  fontSize: '2.5rem',
  color: colors.tertiary,
};

const NameCircle = styled('div')`
  background-color: ${colors.white};
  border-radius: 50rem;
  width: 4.5rem;
  height: 4.5rem;
  color: ${colors.brandBlue.dark};
  letter-spacing: 0.1rem;
`;

const StyledBurgerMenuContainer = styled('div')`
  display: flex;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
`;

const StyledBurgerMenuBar = styled('span')`
  position: relative;
  display: block;
  opacity: 1;
  width: 30px;
  height: 3px;
  background: ${colors.tertiary};
  padding: 0;
  margin-bottom: 5px;
  transition:
    opacity 100ms,
    transform 100ms,
    position 100ms;
  &:first-of-type {
    transform: ${(props: IStyleProps): string => `rotate(${props.open ? 45 : 0}deg)`};
    top: ${(props: IStyleProps): string => `${props.open ? 8 : 0}px`};
  }
  &:nth-of-type(2) {
    opacity: ${(props: IStyleProps): string => `${props.open ? 0 : 1}`};
  }
  &:nth-of-type(3) {
    margin-bottom: 0;
    transform: ${(props: IStyleProps): string => `rotate(${props.open ? 135 : 0}deg)`};
    top: ${(props: IStyleProps): string => `${props.open ? -8 : 0}px`};
  }
`;

interface IStyleProps {
  open?: boolean;
}

/** Single item in user dropdown burger menu. */
const BrgrMenuItem = (props: {
  localizationKey: string;
  icon: JSX.Element;
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
}): JSX.Element => (
  <StyledMenuItem onClick={props.onClick}>
    <StyledTable>
      <tbody>
        <tr>
          <td style={{ width: '3.5rem' }}>{props.icon}</td>
          <td>
            <FormattedMessage id={props.localizationKey} />
          </td>
        </tr>
      </tbody>
    </StyledTable>
  </StyledMenuItem>
);

/** Get event handler that closes the initiating menu and opens the given modal. */
const getToggleHandler =
  (
    e: React.MouseEvent<HTMLElement>,
    toggleMenu: (e: React.MouseEvent<SVGSVGElement | HTMLElement>) => void,
    getModalToggleCb: (s: boolean) => () => void,
  ) =>
  () => {
    toggleMenu(e);
    getModalToggleCb(true)();
  };

const LogoutIcon = styled(({ ...other }) => <img color={'red'} src={logoutIcon} {...other} />)`
  display: block;
  height: 3rem;
  width: 3rem;
`;

interface IMenuContentProps {
  toggleUserMenu: (e: React.MouseEvent<SVGSVGElement | HTMLElement>) => void;
  onClickLogOut: () => void;
  session: ISessionStore;
  setUserSettingsShow: (s: boolean) => () => void;
  setFeedbackShow: (s: boolean) => () => void;
  setInfoShow: (s: boolean) => () => void;
  getStatsToggler: (s: boolean) => () => void;
  setCensored: (censor: string | null) => void;
  censored?: boolean;
  clinicalDialogCapability?: boolean;
  orgSettings?: IState['settings']['orgSettings'];
}
class MenuContent extends React.Component<IMenuContentProps> {
  currentPlatform: string | undefined;
  transferablePlatform: boolean | undefined;
  pid: string | undefined;
  session: ISessionStore | undefined;
  props: IMenuContentProps;

  constructor(props: IMenuContentProps) {
    super(props);
    this.currentPlatform = props.session?.platforms?.selected;
    this.transferablePlatform = isPlatform(this.currentPlatform)
      ? PlatformToArchiveMap[this.currentPlatform].length > 0
      : false;
    this.pid = props.session.data?.patientid;
    this.props = props;
  }

  render(): JSX.Element {
    const { orgSettings } = this.props;

    const powerbiReportId = path(['settings', 'powerbi', this.currentPlatform || '', 'reportId'], orgSettings);

    return (
      <>
        {this.props.session?.data?.visitid && this.props.session?.platforms?.selected && (
          <>
            <Link to="/search" style={{ color: colors.primaryText }}>
              <BrgrMenuItem
                localizationKey="header.search"
                icon={<SearchIcon style={iconStyle} />}
                onClick={this.props.toggleUserMenu}
              />
            </Link>
            {this.props.clinicalDialogCapability && this.transferablePlatform && (
              <Link to="/patientRecords" style={{ color: colors.primaryText }}>
                <BrgrMenuItem
                  localizationKey={'patientRecords.patientRecord'}
                  icon={<SendIcon style={iconStyle} />}
                  onClick={this.props.toggleUserMenu}
                />
              </Link>
            )}
          </>
        )}
        <BrgrMenuItem
          localizationKey="header.userSettings.settings"
          icon={
            <SettingsIcon
              style={{
                display: 'block',
                margin: '0 0 0 0.2rem',
                padding: '0',
                fontSize: '2.5rem',
                color: colors.tertiary,
              }}
            />
          }
          onClick={(e: React.MouseEvent<HTMLElement>): void => {
            this.props.toggleUserMenu(e);
            this.props.setUserSettingsShow(true)();
          }}
        />
        {powerbiReportId && (
          <BrgrMenuItem
            localizationKey="header.stats.modalHeader"
            icon={<BarChart style={iconStyle} />}
            onClick={(e) => getToggleHandler(e, this.props.toggleUserMenu, this.props.getStatsToggler)()}
          />
        )}
        <BrgrMenuItem
          localizationKey="header.feedback"
          icon={<ContactSupport style={iconStyle} />}
          onClick={(e: React.MouseEvent<HTMLElement>): void => {
            this.props.setFeedbackShow(true)();
            this.props.toggleUserMenu(e);
          }}
        />
        <BrgrMenuItem
          localizationKey={this.props.censored ? 'header.showPatientInformation' : 'header.hidePatientInformation'}
          icon={this.props.censored ? <VisibilityIcon style={iconStyle} /> : <VisibilityOffIcon style={iconStyle} />}
          onClick={(e: React.MouseEvent<HTMLElement>): void => {
            this.props.setCensored(this.props.censored ? null : this.pid || null);
            this.props.toggleUserMenu(e);
          }}
        />
        <BrgrMenuItem
          localizationKey="header.softwareInstructions"
          icon={<Help style={iconStyle} />}
          onClick={(e: React.MouseEvent<HTMLElement>): void => {
            fetchWithOptions(`/api/read/fs/softwareinstruction`, { neurojwt: parseJWTFromCookie() }, { method: 'GET' })
              .then(
                (res: Response) => {
                  if (res.status === 200) {
                    return res.blob();
                  } else {
                    throw { status: res.status, fullResponse: res };
                  }
                },
                (error: Error) => {
                  throw error;
                },
              )
              .then((res) => {
                const objectURL = URL.createObjectURL(res);
                window.open(objectURL, '_blank');
              })
              .catch(() => {
                return;
              });
            this.props.toggleUserMenu(e);
          }}
        />
        <BrgrMenuItem
          localizationKey="header.about"
          icon={<InfoIcon style={iconStyle} />}
          onClick={(e: React.MouseEvent<HTMLElement>): void => {
            this.props.setInfoShow(true)();
            this.props.toggleUserMenu(e);
          }}
        />
        <BrgrMenuItem
          localizationKey="header.logout"
          icon={<LogoutIcon />}
          onClick={(e: React.MouseEvent<HTMLElement>): void => {
            this.props.onClickLogOut();
            this.props.toggleUserMenu(e);
          }}
        />
      </>
    );
  }
}

type TProps = IUserInfoLogout & IDispatchFromProps & IStateFromProps;
class UserInfoLogout extends React.Component<TProps> {
  userInfoMenuRef: React.RefObject<HTMLDivElement>;

  constructor(props: TProps) {
    super(props);
    this.userInfoMenuRef = React.createRef();
    this.state = { users: null };
  }

  onClickLogOut = (): void => {
    this.props.logOut && this.props.logOut();
  };

  setCensored = (censor: string | null): void => this.props.toggleCensor(censor);

  render(): JSX.Element {
    const {
      session,
      showMenu,
      setUserSettingsShow,
      setInfoShow,
      getStatsToggler,
      setFeedbackShow,
      toggleUserMenu,
      capabilityGroups = {},
      censored,
      concurrentUsers,
    } = this.props;

    const clinicalDialogCapability = assertCapabilities([PlatformCapabilities.CLINICAL_RECORDS], capabilityGroups);

    const otherUsers = concurrentUsers
      .filter((s) => s.useruuid !== session.data?.useruuid)
      .map((user) => {
        const users = this.props.session.orgUsers;
        if (!users) return { name: null, id: user.useruuid };
        const vaultUser = users.find((u) => u.userId === user.useruuid);
        return { name: vaultUser ? `${vaultUser?.lastNames} ${vaultUser?.firstNames}` : null, id: user.useruuid };
      });

    return (
      <Container style={{ height: '100%', color: colors.tertiary }} alignItems="center" justifyContent="flex-end">
        <Item>
          <IconButton
            id="hamburger"
            color="inherit"
            disableRipple
            style={{
              cursor: 'pointer',
              padding: 0,
              zIndex: showMenu ? 2000 : undefined,
            }}
            onClick={toggleUserMenu}
          >
            <StyledBurgerMenuContainer>
              <div>
                <StyledBurgerMenuBar open={!!showMenu} />
                <StyledBurgerMenuBar open={!!showMenu} />
                <StyledBurgerMenuBar open={!!showMenu} />
              </div>
            </StyledBurgerMenuContainer>
          </IconButton>
          <Popover
            open={!!showMenu}
            anchorEl={showMenu}
            onClose={toggleUserMenu}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            PaperProps={{ elevation: 3, style: { borderRadius: '0.3rem' } }}
            //MenuListProps={{ style: { padding: 0 } }}
            ref={this.userInfoMenuRef}
          >
            <MenuContent
              toggleUserMenu={toggleUserMenu}
              onClickLogOut={this.onClickLogOut.bind(this)}
              session={session}
              setUserSettingsShow={setUserSettingsShow}
              setFeedbackShow={setFeedbackShow}
              setInfoShow={setInfoShow}
              getStatsToggler={getStatsToggler}
              setCensored={this.setCensored.bind(this)}
              censored={censored}
              clinicalDialogCapability={clinicalDialogCapability}
              orgSettings={this.props.orgSettings}
            />
          </Popover>
        </Item>
        <Item style={{ paddingLeft: '2rem' }}>
          <Badge
            badgeContent={
              otherUsers.length > 0 ? (
                <ToolTip
                  content={'+' + otherUsers.length}
                  title={
                    <>
                      <FormattedMessage id="header.userLookingAtSamePatient" />
                      <ul>
                        {otherUsers.map((u) => (
                          <li key={u.id}>{u.name || <FormattedMessage id="header.otherOrgUser" />}</li>
                        ))}
                      </ul>
                    </>
                  }
                  hover
                ></ToolTip>
              ) : undefined
            }
            color={'primary'}
            slotProps={{ badge: { style: { fontSize: '1.6rem', fontWeight: 600 } } }}
          >
            <NameCircle>
              <Container alignItems={'center'} justifyContent={'center'} style={{ height: '100%' }}>
                <Item>
                  <h2 style={{ margin: 0, padding: 0 }}>
                    {session.data?.userfirstnames.charAt(0)}
                    {session.data?.userlastnames.charAt(0)}
                  </h2>
                </Item>
              </Container>
            </NameCircle>
          </Badge>
        </Item>
      </Container>
    );
  }
}

interface IDispatchFromProps {
  logOut?: () => void;
  toggleCensor: (censor: string | null) => void;
}

const mapDispatchToProps = (dispatch: any): IDispatchFromProps => ({
  logOut: (): void => dispatch(actions.logOut()),
  toggleCensor: (censor: string | null): void => dispatch(actions.toggleCensor(censor)),
});

interface IStateFromProps {
  censored?: boolean;
  orgSettings?: IState['settings']['orgSettings'];
  concurrentUsers: Array<{ orgid: string; useruuid: string; patientid: string; jti: string }>;
}

const mapStateToProps = (state: IState): IStateFromProps => ({
  censored: state.session.shouldCensor,
  orgSettings: state.settings.orgSettings,
  concurrentUsers: state.session.websocket.subscribers,
});

interface IUserInfoLogout extends ICapabilityContextProps {
  session: ISessionStore;
  dispatch: any;
  toggleUserMenu: (e: React.MouseEvent<SVGSVGElement | HTMLElement>) => void;
  showMenu: SVGSVGElement | HTMLElement | null;
  setUserSettingsShow: (s: boolean) => () => void;
  setFeedbackShow: (s: boolean) => () => void;
  setInfoShow: (s: boolean) => () => void;
  getStatsToggler: (s: boolean) => () => void;
}

export default withCapabilities(connect(mapStateToProps, mapDispatchToProps)(UserInfoLogout));
