import { Paper } from '@mui/material';
import { Container, Item } from 'Components/Grid';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import ActionButton from '../../components/ActionButton';

const NotificationArea = styled(({ ...other }) => <Paper square={true} {...other} />)`
  max-width: 35rem;
  padding: 3rem;
`;

const NotificationText = styled.div`
  font-size: 1.8rem;
`;

const notificationText = (error?: string): JSX.Element => {
  switch (error?.substr(0, 3)) {
    case 'NAL':
      return <FormattedMessage id="errors.NAL" />;
    case 'NAC':
      return <FormattedMessage id="errors.NAC" />;
    case undefined:
    default:
      return <FormattedMessage id="errors.default" />;
  }
};

const loginRedirect = (uri: string): string => (window.location.href = `${uri}`);

const NotLoggedIn = ({ error, reloginUri, loggedOut = false }: IOwnProps): JSX.Element => (
  <Container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
    <Item>
      <NotificationArea>
        <Container style={{ marginBottom: '3rem' }}>
          <Item>
            <NotificationText>
              {loggedOut ? <FormattedMessage id="errors.loggedOut" /> : notificationText(error)}
            </NotificationText>
          </Item>
        </Container>
        {reloginUri && (
          <Container justifyContent="center">
            <Item>
              <ActionButton text="general.logIn" onClick={loginRedirect.bind(null, reloginUri)} width={20} height={5} />
            </Item>
          </Container>
        )}
      </NotificationArea>
    </Item>
  </Container>
);

interface IOwnProps {
  error?: string;
  reloginUri?: string | null;
  loggedOut?: boolean;
}

export default NotLoggedIn;
