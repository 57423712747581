import { Popover, Slide, styled } from '@mui/material';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import colors from '../../../config/theme/colors';

interface IMenuItemProps {
  preferred?: boolean;
}

const StyledMenuHeader = styled('div')`
  width: 25rem;
  max-width: 25rem;
  padding: 0.5rem 1.2rem;
  box-sizing: border-box;
  background-color: ${colors.white};
  align-items: center;
  font-size: 1.2rem;
  * > &:not(:first-of-type) {
    border-top: 1px solid ${colors.gray};
    padding-top: 1.5rem;
  }
`;
const StyledMenuItem = styled('div')`
  width: 25rem;
  max-width: 25rem;
  padding: 0.5rem 1.2rem;
  box-sizing: border-box;
  background-color: ${colors.white};
  border-top: 1px solid ${colors.gray};
  &:hover {
    background-color: ${colors.lightGray};
  }
  cursor: pointer;
`;

const HeaderText = styled('div')`
  color: ${colors.secondary};
  font-size: 1.2rem;
  line-height: 1.6rem;
  padding 0.5rem 1.2rem;
  font-weight: 600;
  color: ${colors.darkGray};
`;
const PlatformText = styled('div')`
  color: ${colors.secondary};
  font-size: 1.6rem;
  line-height: 1.6rem;
  padding 0.5rem 1.2rem;
  font-weight: ${(props: IMenuItemProps) => (props.preferred ? 600 : 400)};
  color: ${(props: IMenuItemProps) => (props.preferred ? colors.primaryText : colors.gray)};
`;

const PlatformMenu = ({ platformList, preferred, onChangePlatform, anchorEle }: IOwnProps): JSX.Element => {
  const [inset, setInset] = React.useState<number>(3);

  const otherPlatforms = platformList.filter((p) => (preferred ? !preferred.includes(p) : p));

  React.useEffect(() => {
    if (!anchorEle) {
      setInset(3);
      return;
    } else {
      const timer = setTimeout(() => setInset(0), 225);
      return () => clearTimeout(timer);
    }
  }, [anchorEle]);

  return (
    <Popover
      open={!!anchorEle}
      anchorEl={anchorEle}
      anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      TransitionComponent={Slide}
      style={{ zIndex: 10, clipPath: `inset(${inset}% 0 0 0)` }}
      slotProps={{
        paper: {
          style: {
            borderTopLeftRadius: '0',
            borderTopRightRadius: '0',
            borderBottomLeftRadius: '0.3rem',
            borderBottomRightRadius: '0.3rem',
          },
          elevation: 3,
        },
      }}
    >
      {preferred.length > 0 && (
        <>
          <StyledMenuHeader>
            <HeaderText>
              <FormattedMessage id={`header.headers.preferred`} />
            </HeaderText>
          </StyledMenuHeader>
          {preferred.map((p, i: number) => (
            <StyledMenuItem key={i} onClick={onChangePlatform(p)}>
              <PlatformText preferred>
                <FormattedMessage id={`header.platforms.${p}`} />
              </PlatformText>
            </StyledMenuItem>
          ))}
        </>
      )}

      {otherPlatforms.length > 0 && (
        <>
          <StyledMenuHeader>
            <HeaderText>
              <FormattedMessage id={`header.headers.other`} />
            </HeaderText>
          </StyledMenuHeader>

          {otherPlatforms.map((p, i: number) => (
            <StyledMenuItem key={i} onClick={onChangePlatform(p)}>
              <PlatformText>
                <FormattedMessage id={`header.platforms.${p}`} />
              </PlatformText>
            </StyledMenuItem>
          ))}
        </>
      )}
    </Popover>
  );
};

interface IOwnProps {
  platformList: Platform[];
  preferred: Platform[];
  onChangePlatform: (n: Platform) => () => void;
  anchorEle: SVGSVGElement | HTMLElement | null;
}

export default PlatformMenu;
